<template>
  <div class="c_online_homework">
    <div class="stu-module-header">
      <div class="stu-module-title">在线作业</div>
    </div>

    <el-form
      :model="form"
      inline
      class="c_online_homework_form"
      ref="homeForm"
      size="medium"
    >
      <el-form-item label="作业名称" prop="homework">
        <el-input v-model="form.homework" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="作业时间" prop="date">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          format="yyyy/MM/dd"
          value-format="yyyy/MM/dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="query" type="primary">查询</el-button>
        <el-button @click="reset('homeForm')">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" :height="height" v-loading="loading">
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(c, i) in col"
        :prop="c.prop"
        :label="c.label"
        :key="i"
        align="center"
        :min-width="c.width"
        :show-overflow-tooltip="c.tooltip"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="80">
        <template slot-scope="scope">
          <el-button type="text" @click="detail(scope.row)">进入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { QueryStudentWork } from "@/libs/api/online";
export default {
  name: "c_online_homework",
  data() {
    return {
      height: window.innerHeight - 380,
      loading: false,
      form: {
        homework: "",
        date: [],
      },
      col: [
        { prop: "workName", label: "名称", width: 120 },
        { prop: "maxScore", label: "最高评分" },
        {
          prop: "answerStartTime",
          label: "作答开始时间",
          width: 120,
          tooltip: true,
        },
        {
          prop: "answerEndTime",
          label: "作答结束时间",
          widht: 120,
          tooltip: true,
        },
        { prop: "alreadyAnswerNum", label: "作答次数" },
      ],
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
  },
  watch: {
    course(n) {
      n && this.init();
    },
    // $route(to) {
    //   to.query && to.query.init && this.init();
    // },
  },
  mounted() {
    this.init();
  },
  methods: {
    reset(refName) {
      this.$refs[refName].resetFields();
    },
    init() {
      const params = {
        page: this.current,
        pageSize: this.pageSize,
      };
      let data = new FormData();
      data.append("courseId", this.course.id);
      this.form.homework && data.append("info", this.form.homework);
      this.form.date[0] &&
        data.append("startTime", `${this.form.date[0]} 00:00:00`);
      this.form.date[1] &&
        data.append("endTime", `${this.form.date[1]} 00:00:00`);
      this.loading = true;
      QueryStudentWork(params, data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    query() {
      this.init();
    },
    detail(row) {
      if (row) {
        if (row.shortDuration || row.shortDuration === 0) {
          sessionStorage.setItem(
            "stu_work_shortDuration",
            JSON.stringify(row.shortDuration)
          );
        }
        this.$router.push({
          path: "homework/detail",
          query: {
            id: row.workSetId,
          },
        });
      }
    },
    handleCurrentChange(page) {
      if (page) this.current = page;
    },
  },
};
</script>

<style scoped lang="less">
.c_online_homework {
  background: #fff;
  padding: 20px;
  box-shadow: @shadow;
  .c_online_homework_form {
    margin-top: 40px;
  }
}
</style>